import React from 'react'
import { Page, FlexColumn, Text } from '../modules/interface'
import ErrorSvg from '../modules/ui/images/Error.svg'

export default function Custom404() {
  return (
    <Page>
      <FlexColumn>
        <ErrorSvg />
        <Text color="light">Page Not Found</Text>
      </FlexColumn>
    </Page>
  )
}
